import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header'; // Assurez-vous que le chemin d'accès est correct
import Home from './pages/Home/Home';
import Projets from './pages/Projets/Projets';
import Apropos from './pages/Apropos/Apropos';
import LaDifference from './pages/LaDifference/LaDifference'; 
import DeliveryApp from './pages/DeliveryApp/DeliveryApp'; 
import UnisVertGaia from './pages/UnisVertGaia/UnisVertGaia'; 
import Site4 from './pages/Site4/Site4'; 
import Site5 from './pages/Site5/Site5'; 
import { AnimatePresence } from 'framer-motion';

function AppWithRouter() {
    return (
      <Router>
        <App />
      </Router>
    );
  }

function App() {
    const location = useLocation();
    return (
        <AnimatePresence mode="wait">
            <Header /> 
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                {/* <Route path="/works" element={<Projets />} /> */}
                <Route path="/about" element={<Apropos />} />
                <Route path="/ladifference" element={<LaDifference />} />
                <Route path="/delivery" element={<DeliveryApp />} />
                <Route path="/unis" element={<UnisVertGaia />} />
                {/* <Route path="/site4" element={<Site4 />} />
                <Route path="/site5" element={<Site5 />} /> */}
            </Routes>

    </AnimatePresence>
    );
}

export default AppWithRouter;


