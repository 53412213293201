import React, { useState, useEffect, useRef } from "react";
import "./../css/DisplayProjects.css";
import { Link } from "react-router-dom";
import projects from "./../datas/projects";

function DisplayProjects() {
  const [hoveredProjectId, setHoveredProjectId] = useState(null);
  const [activeProjectId, setActiveProjectId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    const children = container.children;
    const containerWidth = container.clientWidth;
    const threshold = window.innerWidth * 0.05;
    
    const paddingLeft = containerWidth * 0.25; // 25% de la largeur du conteneur pour le premier élément
    const paddingRight = paddingLeft; // Assumons que le padding gauche et droit sont identiques

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const childMidPoint = child.offsetLeft + child.clientWidth / 2;
      let containerMidPoint = container.scrollLeft + containerWidth / 2;

      // Ajuster le point médian du conteneur pour le premier et le dernier élément
      if (i === 0) {
        containerMidPoint -= paddingLeft;
      } else if (i === children.length - 1) {
        containerMidPoint += paddingRight;
      }

      if (childMidPoint >= containerMidPoint - threshold && childMidPoint <= containerMidPoint + threshold) {
        setActiveProjectId(projects[i].id);
        break;
      }
    }
};


  const getProjectStyle = (project) => {
    return {
      backgroundImage: `url(${project.imageUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: hoveredProjectId === project.id ? '100%' : '90%',
      filter: hoveredProjectId === project.id ? 'grayscale(0)' : 'grayscale(100)',
      opacity: hoveredProjectId === project.id ? 1 : 0.35,
    };
  };

  return (
    <>
      <section className="project-section"> 
      {projects.map((project) => (
            activeProjectId === project.id && (
              <div className="title_project_mobile" key={project.id}>
                    <h1>{project.title}</h1>
              </div>
            )
          ))}
        <div className="images-section">
          {projects.map((project) => (
            <Link key={project.id} to={project.lien} className="Link">   
              <div
                className={`box_project ${loaded ? "animated" : ""}`}
                onMouseEnter={() => setHoveredProjectId(project.id)}
                onMouseLeave={() => setHoveredProjectId(null)}
                style={getProjectStyle(project)}
              ></div>
            </Link>
          ))}
        </div>
        <div 
          className="images-section-mobile"
          ref={scrollContainerRef}
        >
          {projects.map((project, index) => (
            <Link 
              key={project.id} 
              to={project.lien} 
              className="Link" 
              style={{
                paddingLeft: index === 0 ? '25%' : '0',
                paddingRight: index === projects.length - 1 ? '25%' : '0'
              }}
            >
              <img src={project.imageUrl} alt={project.alt}/>
            </Link>
          ))}
        </div>
        {projects.map((project) => (   
          <div
            key={project.id}
            className={`box_title ${hoveredProjectId === project.id ? "visible" : ""}`}
          >
            <h3>{project.title}</h3>
          </div>
        ))}
        <div className="infos-section">
          {projects.map((project) => (
            hoveredProjectId === project.id && (
              <div className="infos_project" key={project.id}>
                <div className="project-content">
                  <ul>
                    <li>{project.categorie}</li>
                    <li>{project.date}</li>
                    <li>{project.logiciel}</li>
                  </ul>
                </div>
              </div>
            )
          ))}
        </div>
        <div className="infos-section-mobile">
          {projects.map((project) => (
            activeProjectId === project.id && (
              <div className="infos_project" key={project.id}>
                  <ul>
                    <li>{project.categorie}</li>
                    <li>{project.date}</li>
                    <li>{project.logiciel}</li>
                  </ul>
              </div>
            )
          ))}
        </div>
      </section>
    </>
  );
}

export default DisplayProjects;



