import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import { Link } from "react-router-dom";
import "./../../css/DeliveryApp.css";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}

function LaDifference() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState({
    img: "/DeliveryDesktop1.png",
    imgMobile: "/DeliveryDesktop1.png",
    thumbnail: "/Delivery1.svg",
    subtitles: [
      "Delivery App est une application de livraison qui a pour objectif",
      "de permettre à l'utilisateur de commander des plats auprès de restaurants.",
      "Il s'agit d'un projet fictif.",
      "Cliquez sur les miniatures en bas pour voir la suite",
      "",
    ],
  });
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [isFirstAudioDisabled, setIsFirstAudioDisabled] = useState(false);

  // const audioRef1 = useRef(new Audio("/audios/Unis1.mp3"));
  const audioRef2 = useRef(new Audio("/audios/Deli.mp3"));
  const projectAudioRef = useRef(null);
  const videoRef = useRef(null)

  const projects = [
        {
          img: "/DeliveryDesktop1.png",
          imgMobile: "/DeliveryDesktop1.png",
          thumbnail: "/Delivery1.svg",
          subtitles: [
            "Delivery App est une application de livraison qui a pour objectif",
            "de permettre à l'utilisateur de commander des plats auprès de restaurants.",
            "Il s'agit d'un projet fictif.",
            "Cliquez sur les miniatures en bas pour voir la suite",
            "",
          ],
        },
        {
          img: "/DeliveryDesktop2.png",
          imgMobile: "/DeliveryDesktop2.png",
          thumbnail: "/Delivery2.svg",
          subtitles: [
            
            "Après avoir fait son choix l'utilisateur peut ajuster la quantité",

            "de nourriture qu'il souhaite.",

            "[FIN, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN]",

            "",
          ],
        },
        {
          img: "/DeliveryDesktop3.png",
          imgMobile: "/DeliveryDesktop3.png",
          thumbnail:"/Delivery3.svg",
          subtitles: [
            "L'application propose un système de recommandation qui s'adapte aux choix que vous avez effectués précédemment.",
            "[SOUS-TITRES TERMINÉES, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
        {
          img: "/DeliveryDesktop4.png",
          imgMobile: "/DeliveryDesktop4.svg",
          thumbnail:"/Delivery4.svg",
          subtitles: [
            "L'utilisateur peut vérifier les produits qu'il a choisi dans le panier.",
            "Ce projet fictif m'a permis de travailler mes compétences en UX/UI ainsi que mes compétences sur Figma.",
            "[SOUS-TITRES TERMINÉES, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
      ];

  useEffect(() => {
    setSubtitleIndex(0);
    const intervalId = setInterval(() => {
      setSubtitleIndex((currentIndex) => (currentIndex + 1) % selectedProject.subtitles.length);
    }, 4490);

    return () => clearInterval(intervalId);
  }, [selectedProject]);

  useEffect(() => {
    const playSoundOnMouseMove = () => {
      // if (!isFirstAudioDisabled) {
      //   // audioRef1.current.play().catch((error) => {});
      // }
      audioRef2.current.play().catch((error) => {});
    };

    window.addEventListener("mousemove", playSoundOnMouseMove);

    return () => {
      // audioRef1.current.pause();
      // audioRef1.current.currentTime = 0;
      audioRef2.current.pause();
      audioRef2.current.currentTime = 0;
      window.removeEventListener("mousemove", playSoundOnMouseMove);
    };
  }, [isFirstAudioDisabled]);

  useEffect(() => {
    return () => {
      if (projectAudioRef.current) {
        projectAudioRef.current.pause();
        projectAudioRef.current.currentTime = 0;
      }
    };
  }, [navigate]);

  
  const handleProjectClick = (project) => {
    setIsFirstAudioDisabled(false);
    setSelectedProject(project);
    // const video = videoRef.current;
    // video.src = getImagePath(project);
    // video.play();

    // Arrêter l'audio précédent si nécessaire
    // if (projectAudioRef.current) {
    //   projectAudioRef.current.pause();
    //   projectAudioRef.current.currentTime = 0;
    // }

    // Jouer l'audio du nouveau projet
    // projectAudioRef.current = new Audio(project.audio);
    // projectAudioRef.current.play().catch((error) => console.error("Erreur lors de la lecture de l'audio:", error));
  };
  const getImagePath = (project) => isMobile ? project.imgMobile : project.img;
  
  return (
    <>
    <div className="bodyDelivery">
      <div className="LogoDelivery">
        <Link to="/">
          <img src="/LogoB.svg" alt="Logo" />
        </Link>
        <h1>DELIVERY APP</h1>
      </div>
      <div className="heroDifference">
        <div className="slider">
          <img className="video" ref={videoRef} src={getImagePath(selectedProject)} type="video/mp4" />
          <div className="subtitle">{selectedProject.subtitles[subtitleIndex]}</div>
          <div className="slider_thumbnails">
          <div className="slider_thumbnails__images">
            {projects.map((project, index) => (
              <>
                <img
                  key={index}
                  src={project.thumbnail}
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleProjectClick(project)}
                  style={{
                    cursor: "pointer",
                    opacity: selectedProject.img === project.img ? 1 : 0.5,
                    transition: "opacity 0.3s ease",
                  }}
                />
              </>
            ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PageTransition(LaDifference);