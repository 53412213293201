import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import { Link } from "react-router-dom";
import "./../../css/Projets.css";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}

function LaDifference() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState({
    img: "/videos/debut.mp4",
    imgMobile: "/videos/debut-mobile.mp4",
    thumbnail: "/thumb.jpg",
    // audio: "/audios/Unis1.mp3",
    subtitles: [
            "Plongez dans l'univers immersif d'Unis Vert Gaïa",

            "Un prototype de site en réalité virtuelle conçu durant ma 1re année de master",

            "Ce site e-commerce en réalité virtuelle s'inspire fortement de l'ambiance que",

            "l'on peut trouver dans les magasins \"Nature et Découvertes\"",

            "Vous avez la possibilité d'explorer plusieurs lieux selon vos envies",

            "Comme par exemple le coin camping vous permettant de visualiser et de tester des",

            "produits pour le camping, la pêche (dans le coin pêche) et de yoga",

            "La problématique principale était d'adapter l'expérience utilisateur à la réalité virtuelle",

            "Je vous invite à cliquer sur les miniatures en bas de l'écran afin de",

            "découvrir les différentes étapes de conception de ce projet en VR",

            "[FIN, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN]",

            "",
    ],
  });
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [isFirstAudioDisabled, setIsFirstAudioDisabled] = useState(false);

  // const audioRef1 = useRef(new Audio("/audios/Unis1.mp3"));
  const audioRef2 = useRef(new Audio("/audios/mood.mp3"));
  const projectAudioRef = useRef(null);
  const videoRef = useRef(null)

  const projects = [
        {
          img: "/videos/debut.mp4",
          imgMobile: "/videos/debut-mobile.mp4",
          thumbnail: "/thumb.jpg",
          // audio: "/audios/Unis1.mp3",
          subtitles: [
            "Plongez dans l'univers immersif d'Unis Vert Gaïa",

            "Un prototype de site en réalité virtuelle conçu durant ma 1re année de master",

            "L'ambiance de ce site e-commerce en réalité virtuelle s'apparente à celle",

            "des magasins qui invitent à la découverte et à l'évasion.",

            "Vous avez la possibilité d'explorer plusieurs lieux selon vos envies",

            "Comme par exemple le coin camping vous permettant de visualiser et de tester des",

            "produits pour le camping, la pêche (dans le coin pêche) et de yoga",

            "La problématique principale était d'adapter l'expérience utilisateur à la réalité virtuelle",

            "Je vous invite à cliquer sur les miniatures en bas de l'écran afin de",

            "découvrir les différentes étapes de conception de ce projet en VR",

            "[FIN, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN]",

            "",
          ],
        },
        {
          img: "/videos/Tuto.mp4",
          imgMobile: "/videos/tuto-mobile.mp4",
          thumbnail:"/Tuto.jpg",
          // audio: "/audios/Unis2.mp3",
          subtitles: [
            "Afin que l'utilisateur comprenne comment naviguer via l'Oculus Rift",
            "au sein de cet environnement en réalité virtuelle.",
            "J'ai décidé de concevoir un petit tutoriel (un motion design avec After Effect)",
            "expliquant comme se déplacer au sein de ce monde en 3 dimensions",
            "[SOUS-TITRES TERMINÉS, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
        {
          img: "/videos/camping-build_1.mp4",
          imgMobile: "/videos/camping-mobile.mp4",
          thumbnail:"/Yoga.jpg",
          // audio: "/audios/Unis3.mp3",
          subtitles: [
            "Pour concevoir ce prototype en réalité virtuel",
            "le logiciel choisi était Unreal Engine 4",
            "Ce moteur de jeu m'a permis de modéliser les différents environnements",
            "disponibles à l'utilisateurs et d'ajouter les sons, éléments 3D, les jeux de lumières",
            "indispensables pour une immersion totale.",
            "[SOUS-TITRES TERMINÉS, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
        {
          img: "/videos/logo.mp4",
          imgMobile: "/videos/logo_mobile.mp4",
          thumbnail:"/thumbnail_logo.jpg",
          // audio: "/audios/Unis4.mp3",
          subtitles: [
            "Pour ce qui est de la conception du logo, nous pouvons voir deux références",
            "à la déesse Gaïa signifiant Terre en grec ancien",
            "et qui fait référence à la déesse primordiale de la Terre.",
            "Nous pouvons voir les deux formes arrondies précédentes en haut et en bas du logo",
            "qui symbolisent les mains de la déesse, la Terre est représentée par un cercle",
            "se trouvant au milieu comme englobé par les mains de Gaïa",
            "nous pouvons deviner la forme du casque de réalité virtuelle",
            "la forme du logo peut aussi faire penser à un œil et l'arbre au centre fait référence",
            "à la nature, l'environnement et à l'écologie qui sont les thèmes de ce site marchand",
            "[SOUS-TITRES TERMINÉS, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
        {
          img: "/videos/fin.mp4",
          imgMobile: "/videos/fin-mobile.mp4",
          thumbnail: "/thumbnail_finUnis.jpg",
          // audio: "/audios/Unis5.mp3",
          subtitles: [
            "Ce projet universitaire m'a permis de travailler plusieurs compétences",
            "commme l'expérience utilisateur, l'ergonomie ainsi que la modélisation 3D",
            "[SOUS-TITRES TERMINÉS, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
      ];

  useEffect(() => {
    setSubtitleIndex(0);
    const intervalId = setInterval(() => {
      setSubtitleIndex((currentIndex) => (currentIndex + 1) % selectedProject.subtitles.length);
    }, 4490);

    return () => clearInterval(intervalId);
  }, [selectedProject]);

  useEffect(() => {
    const playSoundOnMouseMove = () => {
      // if (!isFirstAudioDisabled) {
      //   // audioRef1.current.play().catch((error) => {});
      // }
      audioRef2.current.play().catch((error) => {});
    };

    window.addEventListener("mousemove", playSoundOnMouseMove);

    return () => {
      // audioRef1.current.pause();
      // audioRef1.current.currentTime = 0;
      audioRef2.current.pause();
      audioRef2.current.currentTime = 0;
      window.removeEventListener("mousemove", playSoundOnMouseMove);
    };
  }, [isFirstAudioDisabled]);

  useEffect(() => {
    return () => {
      if (projectAudioRef.current) {
        projectAudioRef.current.pause();
        projectAudioRef.current.currentTime = 0;
      }
    };
  }, [navigate]);

  
  const handleProjectClick = (project) => {
    setIsFirstAudioDisabled(false);
    setSelectedProject(project);
    const video = videoRef.current;
    video.src = getImagePath(project);
    video.play();

    // Arrêter l'audio précédent si nécessaire
    // if (projectAudioRef.current) {
    //   projectAudioRef.current.pause();
    //   projectAudioRef.current.currentTime = 0;
    // }

    // // Jouer l'audio du nouveau projet
    // projectAudioRef.current = new Audio(project.audio);
    // projectAudioRef.current.play().catch((error) => console.error("Erreur lors de la lecture de l'audio:", error));
  };
  const getImagePath = (project) => isMobile ? project.imgMobile : project.img;
  
  return (
    <div className="body">
      <div className="Logo">
        <Link to="/">
          <img src="/LogoVert.svg" alt="Logo" />
        </Link>
        <h1>UNIS VERT GAÏA</h1>
      </div>
      <div className="hero">
        <div className="slider">
          {/* <img src={getImagePath(selectedProject)} alt="Featured Project" /> */}
          <video className="video" ref={videoRef} loop muted autoPlay>
            <source src={getImagePath(selectedProject)} type="video/mp4" />
          </video>

          <div className="subtitle">{selectedProject.subtitles[subtitleIndex]}</div>
          <div className="slider_thumbnails">
          <div className="slider_thumbnails__images">
            {projects.map((project, index) => (
              <>
                <img
                  key={index}
                  src={project.thumbnail}
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleProjectClick(project)}
                  style={{
                    cursor: "pointer",
                    opacity: selectedProject.img === project.img ? 1 : 0.5,
                    transition: "opacity 0.3s ease",
                  }}
                />
              </>
            ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTransition(LaDifference);