import React from "react";
import "./../../css/home.css";
import DisplayProjects from "../../components/DisplayProjects";
import LogoSection from "../../components/LogoSection";
import Transition from "./../../components/PageTransition"

function Home() {
  return (
    <>
      <LogoSection/>
      <main>
        <DisplayProjects/>
        <div className="circle1"></div>
        <div className="spirale">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 1219 982"
            fill="none"
          >
            <path
              d="M494.673 383.286C477.526 382.143 444.776 389.972 450.949 430.429C458.665 481 563.683 488.45 582.978 396.143C594.983 338.714 567.244 287.286 494.673 287.286C404.654 287.286 290.626 312.143 309.49 498.143C325.59 656.895 500.674 633.572 582.978 635.286C665.283 637 861.143 592.429 849.998 319C840.704 91 716.154 32.5001 517.998 32.5C182.884 32.4999 19.4633 152.178 2.99832 444C-11.502 701 60.861 985 482.67 985C1077.66 985 1162.77 710.429 1218.5 -31"
              stroke="#625839"
              stroke-opacity="0.12"
            />
          </svg>
        </div>
        <div class="vertical-line"></div>
        <div className="triangle">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 954 945"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.49828 943.75L477 1.11016L952.502 943.75H1.49828Z"
              stroke="#625839"
              stroke-opacity="0.12"
            />
          </svg>
        </div>
        <div className="rectangle"></div>
        <div className="circle2"></div>
      </main>
    </>
  );
}

export default Transition(Home);
