import React, { useState, useEffect, useRef } from "react";
import PageTransition from "../../components/PageTransition";

function Apropos() {
  
  return (
    <>
     <h1>A propos</h1>
     <p>Mail : paul.lavergne99@outlook.fr</p>
     <a>Mentions Légales</a>
    </>
  );
}

export default PageTransition(Apropos);