import React from "react";
import "./../css/LogoSection.css";

function LogoSection() {
  return (
    <section className="section_logo">
      <div>
        <h1>PAUL LAVERGNE</h1>
        <img src="/Logo.svg" alt="Logo"/>
        <h2>
          UX/UI designer
          <br />
          front-end developer
        </h2>
      </div>

    </section>
  );
}

export default LogoSection;
