const projects = [
    {
      id: 1,
      title: "LA DIFFERENCE",
      imageUrl: "/LogoLaDifferenceAccueil.svg",
      categorie: "Web",
      date: "2022",
      logiciel: "Adobe XD & Illustrator",
      lien: "/ladifference",
      alt: "lorem",
    },
    {
      id: 2,
      title: "DELIVERY APP",
      imageUrl: "/DeliveryDesktop1.svg",
      categorie: "UX/UI",
      date: "2023",
      logiciel: "Figma & React native",
      lien: "/delivery",
      alt: "lorem",
    },
    {
      id: 3,
      title: "UNIS VERT GAÏA",
      imageUrl: "/LaDifferenceHome.svg",
      categorie: "Virtual reality",
      date: "2021",
      logiciel: "Unreal engine & after effect",
      lien: "/unis",
      alt: "lorem",
    },
    // {
    //   id: 4,
    //   title: "SITE4",
    //   imageUrl: "/Projet1_Accueil.svg",
    //   categorie: "Description du projet 1",
    //   date: "2021",
    //   logiciel: "Unreal engine & after effect",
    //   lien: "/site4",
    //   alt: "lorem",
    // },
    // {
    //   id: 5,
    //   title: "SITE5",
    //   imageUrl: "/Projet1_Accueil.svg",
    //   categorie: "Description du projet 1",
    //   date: "2021",
    //   logiciel: "Unreal engine & after effect",
    //   lien: "/site5",
    //   alt: "lorem",
    // },
  ];

  export default projects;