import { motion } from 'framer-motion';
import "./../css/Projets.css"

const PageTransition = ( Ogcom ) => {
    return () => (
        <>
            <Ogcom/>
            <motion.div
            className="slide-in"
            initial={{scaleY:0}}
            animate={{scaleY:0}}
            exit={{scaleY: 1}}
            transition={{duration: 1, ease:[0.22, 1, 0.36, 1]}}
            />
            <motion.div
            className="slide-out"
            initial={{scaleY:1}}
            animate={{scaleY:0}}
            exit={{scaleY: 0}}
            transition={{duration: 1, ease:[0.22, 1, 0.36, 1]}}
            />
        </>
    )
};

export default PageTransition;

// import { motion, AnimatePresence } from 'framer-motion';

// const PageTransition = (OgCom) => {
//   return function WrappedComponent(props) {
//     return (
//       <>
//         <AnimatePresence>
//           <motion.div
//             className="page-transition"
//             initial={{ scaleY: 0 }}
//             animate={{ scaleY: 1 }}
//             exit={{ scaleY: 0 }}
//             transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
//             style={{ 
//               transformOrigin: 'top',
//               position: 'fixed',
//               left: 0,
//               top: 0,
//               width: '100%',
//               height: '100%',
//               backgroundColor: 'green' // Ou tout autre style que vous souhaitez
//             }}
//           />
//         </AnimatePresence>
//         <OgCom {...props} />
//       </>
//     );
//   };
// };

// export default PageTransition