import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PageTransition from "../../components/PageTransition";
import { Link } from "react-router-dom";
import "./../../css/Difference.css";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}

function LaDifference() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState({
    img: "/LogoLaDifference.png",
    imgMobile: "/LogoLaDifference.png",
    thumbnail: "/LogoLaDifference.png",
    subtitles: [
      "Cliquez sur les miniatures en bas pour voir la suite",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
  });
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [isFirstAudioDisabled, setIsFirstAudioDisabled] = useState(false);

  // const audioRef1 = useRef(new Audio("/audios/Unis1.mp3"));
  const audioRef2 = useRef(new Audio("/audios/diff.mp3"));
  const projectAudioRef = useRef(null);
  const videoRef = useRef(null)

  const projects = [
        {
          img: "/LogoLaDifference.png",
          imgMobile: "/LogoLaDifference.png",
          thumbnail: "/LogoLaDifference.png",
          subtitles: [
            "Cliquez sur les miniatures en bas pour voir la suite",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        },
        {
          img: "/difference1.png",
          imgMobile: "/difference1.png",
          thumbnail: "/difference1.png",
          subtitles: [
            
            "J'ai eu le plaisir de travailler sur les maquettes et le prototype",

            "d'un site e-commerce pour la librairie \"La Différence\" située à Paris.",

            "Mon objectif était de créer une plateforme intuitive et élégante qui reflète ",

            "l'identité unique de la librairie et offre une expérience d'achat optimale aux clients",

            "[FIN, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN]",

            "",
          ],
        },
        {
          img: "/storyboard.png",
          imgMobile: "/storyboard.png",
          thumbnail:"/storyboard.png",
          subtitles: [
            "J'ai réalisé un storyboard complet pour les versions desktop et mobile du site",
            "e-commerce de la librairie \"La Différence\".",
            "Ce storyboard scénarise en détail les interactions de l'utilisateur à chaque",
            "étape du parcours client, depuis la navigation sur le site jusqu'à la validation de la commande.",
            "Il permet de visualiser l'ensemble des fonctionnalités du site et de garantir une expérience utilisateur fluide et intuitive sur tous les supports.",
            "[SOUS-TITRES TERMINÉES, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
        {
          img: "/difference2.png",
          imgMobile: "/difference2.png",
          thumbnail:"/difference2.png",
          subtitles: [
            "J'ai acquis une solide compréhension des enjeux d'un site e-commerce et",
            "développé une méthodologie rigoureuse pour concevoir une interface intuitive et efficace.",
            "De la création de maquettes à la réalisation du prototype et du storyboard, j'ai",
            "pu mettre en pratique mes connaissances et les adapter aux besoins",
            "spécifiques de la librairie \"La Différence\".",
            "Ce travail m'a permis de gagner en précision et en créativité",
            "et je suis fier d'avoir contribué à la réussite de ce projet.",
            "[SOUS-TITRES TERMINÉES, CLIQUEZ SUR LA VIGNETTE SUIVANTE EN BAS DE L'ECRAN QUAND LA VIDÉO EST TERMINÉE]",
            "",
          ],
        },
      ];

  useEffect(() => {
    setSubtitleIndex(0);
    const intervalId = setInterval(() => {
      setSubtitleIndex((currentIndex) => (currentIndex + 1) % selectedProject.subtitles.length);
    }, 4490);

    return () => clearInterval(intervalId);
  }, [selectedProject]);

  useEffect(() => {
    const playSoundOnMouseMove = () => {
      // if (!isFirstAudioDisabled) {
      //   // audioRef1.current.play().catch((error) => {});
      // }
      audioRef2.current.play().catch((error) => {});
    };

    window.addEventListener("mousemove", playSoundOnMouseMove);

    return () => {
      // audioRef1.current.pause();
      // audioRef1.current.currentTime = 0;
      audioRef2.current.pause();
      audioRef2.current.currentTime = 0;
      window.removeEventListener("mousemove", playSoundOnMouseMove);
    };
  }, [isFirstAudioDisabled]);

  useEffect(() => {
    return () => {
      if (projectAudioRef.current) {
        projectAudioRef.current.pause();
        projectAudioRef.current.currentTime = 0;
      }
    };
  }, [navigate]);

  
  const handleProjectClick = (project) => {
    setIsFirstAudioDisabled(false);
    setSelectedProject(project);
    // const video = videoRef.current;
    // video.src = getImagePath(project);
    // video.play();

    // Arrêter l'audio précédent si nécessaire
    // if (projectAudioRef.current) {
    //   projectAudioRef.current.pause();
    //   projectAudioRef.current.currentTime = 0;
    // }

    // Jouer l'audio du nouveau projet
    // projectAudioRef.current = new Audio(project.audio);
    // projectAudioRef.current.play().catch((error) => console.error("Erreur lors de la lecture de l'audio:", error));
  };
  const getImagePath = (project) => isMobile ? project.imgMobile : project.img;
  
  return (
    <>
    
      <video autoPlay muted loop id="myVideo">
        <source src="/videos/BackgroundDifference.mp4" type="video/mp4"/>
      </video>
      <video autoPlay muted loop id="myVideomobile">
        <source src="/videos/BackgroundDifferenceMobile.mp4" type="video/mp4"/>
      </video>
    <div className="bodyDifference">
      <div className="LogoDifference">
        <Link to="/">
          <img src="/LogoNoir.svg" alt="Logo" />
        </Link>
        <h1>LA DIFFÉRENCE</h1>
      </div>
      <div className="heroDifference">
        <div className="slider">
          <img className="video" ref={videoRef} src={getImagePath(selectedProject)} type="video/mp4" />
          <div className="subtitle">{selectedProject.subtitles[subtitleIndex]}</div>
          <div className="slider_thumbnails">
          <div className="slider_thumbnails__images">
            {projects.map((project, index) => (
              <>
                <img
                  key={index}
                  src={project.thumbnail}
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleProjectClick(project)}
                  style={{
                    cursor: "pointer",
                    opacity: selectedProject.img === project.img ? 1 : 0.5,
                    transition: "opacity 0.3s ease",
                  }}
                />
              </>
            ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PageTransition(LaDifference);