import React from "react";
import { Link } from "react-router-dom";
import "./../css/header.css";

function Header() {
  const currentPage = window.location.pathname; // Get current page path

  let headerClass;

  switch (currentPage) {
    case '/unis':
      headerClass = 'header-UnisVertGaia';
      break;
    case '/ladifference':
      headerClass = 'header-LaDifference';
      break;
    case '/delivery':
      headerClass = 'header-DeliveryApp';
      break;
    default:
      headerClass = 'header'; // Default class for other pages
  }
  return (
    <header className={headerClass}>
      <nav>
        <ul>
          <li className="Retour">
            <Link to="/">RETOUR</Link>
          </li>
          <li>
            <a href="/CV.pdf" target="_blank" rel="noopener noreferrer">
              CV
            </a>
          </li>
          <li>
            <Link to="/about">ABOUT</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
